// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app/[...].jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-forgot-jsx": () => import("./../../../src/pages/forgot.jsx" /* webpackChunkName: "component---src-pages-forgot-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-tools-jsx": () => import("./../../../src/pages/tools.jsx" /* webpackChunkName: "component---src-pages-tools-jsx" */)
}

